import React from "react";

import Cta from "../../components/Cta";

import { Container } from "./styles";

const SectionOrcamento = () => {
  return (
    <Container>
      <h1>Pague o mínimo de energia,</h1>
      <h1>consuma o máximo!</h1>
      <hr />
      <Cta />
    </Container>
  );
};

export default SectionOrcamento;
