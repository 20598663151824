import styled from "styled-components";

export const Container = styled.section`
  height: calc(100% - 116px);
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
  display: flex;

  @media (max-width: 1200px) {
    padding: 0 32px;
  }
`;

export const Box = styled.div`
  margin-top: 76px;
`;

export const H1 = styled.h1`
  font-size: 32px;
  line-height: 42px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);

  @media (max-width: 860px) {
    font-size: 26px;
    line-height: 36px;
  }
`;
export const H2 = styled.h1`
  font-size: 64px;
  line-height: 74px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);

  @media (max-width: 860px) {
    font-size: 54px;
    line-height: 64px;
  }

  @media (max-width: 440px) {
    font-size: 44px;
    line-height: 54px;
  }
`;
