import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  padding: 80px 32px;

  h1 {
    color: #fff;
    font-size: 46px;
    font-weight: bold;
    line-height: 56px;
    text-align: center;

    @media (max-width: 520px) {
      font-size: 36px;
    }
  }

  hr {
    margin: 0 auto;
    margin-top: 24px;
    width: 140px;
    height: 3px;
    background: #fff;
    border: 0;
  }
`;

export const WrapperCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;

  @media (max-width: 760px) {
    flex-direction: column;
  }
`;

export const Card = styled.article`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  padding: 32px 16px;

  svg,
  img {
    height: 80px;
    width: 80px;

    color: #fff;
  }

  strong {
    margin-top: 16px;
    color: #fff;
    font-size: 22px;
  }
`;
