import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import Img from "gatsby-background-image";

const Painel = ({ children }) => {
  const img = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "bgvantagens.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxHeight: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Img fluid={img.file.childImageSharp.fluid} alt="Painel Solar">
      {children}
    </Img>
  );
};

const StyledImg = styled(Painel)`
  background-position: right bottom;
`;

export default StyledImg;
