import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  margin-top: 100px;

  h1 {
    font-size: 46px;
    font-weight: bold;
    text-align: center;
    padding: 0 32px;

    @media (max-width: 885px) {
      font-size: 36px;
    }

    @media (max-width: 400px) {
      font-size: 28px;
    }
  }

  hr {
    margin: 0 auto;
    margin-top: 32px;
    width: 140px;
    height: 3px;
    background: #e66000;
    border: 0;
  }
`;

export const WrapperCard = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-direction: column;
  padding-right: 40px;
  padding-top: 64px;

  min-height: 500px;

  @media (max-width: 1180px) {
    min-height: 800px;
  }

  @media (max-width: 840px) {
    flex-direction: column;
    min-height: 864px;
  }

  @media (max-width: 580px) {
    min-height: 920px;
  }

  @media (max-width: 428px) {
    min-height: 1050px;
  }
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 32px;

  svg {
    width: 24px;
    height: 24px;
    color: #e66000;
  }

  strong {
    margin-left: 14px;
    font-size: 22px;
    line-height: 32px;
  }

  @media (max-width: 580px) {
    align-items: flex-start;
    svg {
      width: 20px;
      height: 20px;
      color: #e66000;
    }
    strong {
      margin-left: 14px;
      font-size: 15px;
      line-height: 28px;
    }
  }
`;
