import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  max-width: 1120px;
  margin: 100px auto;
  padding: 0 32px;

  h1 {
    font-size: 46px;
    font-weight: bold;
    text-align: center;

    @media (max-width: 885px) {
      font-size: 36px;
    }

    @media (max-width: 400px) {
      font-size: 28px;
    }
  }

  hr {
    margin: 0 auto;
    margin-top: 32px;
    width: 140px;
    height: 3px;
    background: #e66000;
    border: 0;
  }
`;

export const Card = styled.article`
  display: flex;
  flex: 1;
  padding: 24px;

  span {
    margin-right: 16px;
    margin-top: 6px;

    svg {
      color: #e66000;
    }
  }

  div {
    strong {
      font-size: 22px;
    }

    p {
      margin-top: 16px;
      font-size: 16px;
      color: #818181;
    }
  }

  @media (max-width: 885px) {
    padding-bottom: 40px;
  }

  @media (max-width: 512px) {
    flex-direction: column;

    span {
      text-align: center;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 400px) {
    div {
      strong {
        font-size: 18px;
      }
    }
  }
`;

export const WrapperCard = styled.article`
  display: flex;
  margin-top: 72px;

  @media (max-width: 885px) {
    flex-direction: column;
  }
`;
