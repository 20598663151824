import React from "react";

import { Container } from "./styles";

const SectionEmpresa = () => {
  return (
    <Container>
      <h3>
        BEM VINDOS À <strong>SF SOLAR</strong>
      </h3>
      <h1>Energia Solar é limpa, sustentável e econômica</h1>
      <hr />
      <p>
        A <strong>SF Solar</strong> é uma empresa voltada a redução de custos
        pagos pelo consumo de energia, através da implantação de sistemas
        fotovoltaicos que possibilitam a geração da própria energia utilizando
        fontes limpas e renováveis.{" "}
      </p>
    </Container>
  );
};

export default SectionEmpresa;
