import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import Img from "gatsby-background-image";

const Bg = ({ className, children }) => {
  const bg = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "business.jpg" }) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Img
      fluid={bg.file.childImageSharp.fluid}
      className={className}
      alt="Soluções"
    >
      {children}
    </Img>
  );
};

const StyledBg = styled(Bg)`
  width: 100%;
  height: 100%;
  background-position: bottom center;
`;

export default StyledBg;
