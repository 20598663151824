import React from "react";
import { GoCheck } from "react-icons/go";

import Bg from "./Img";

import { Container, WrapperCard, Card } from "./styles";

const SectionBenefits = () => {
  return (
    <Container>
      <h1>Vantagens do Sistema Solar Fotovoltaico</h1>
      <hr />
      <Bg>
        <WrapperCard>
          <div>
            <Card>
              <GoCheck />
              <strong>Redução de até 95% na fatura de energia</strong>
            </Card>
            <Card>
              <GoCheck />
              <strong>Valorização imediata do imóvel</strong>
            </Card>
            <Card>
              <GoCheck />
              <strong>Investimento com alto retorno</strong>
            </Card>

            <Card>
              <GoCheck />
              <strong>Baixa manutenção</strong>
            </Card>
            <Card>
              <GoCheck />
              <strong>Longa vida útil (superior a 30 anos)</strong>
            </Card>
            <Card>
              <GoCheck />
              <strong>Imunização a inflação energética</strong>
            </Card>
          </div>
        </WrapperCard>
      </Bg>
    </Container>
  );
};

export default SectionBenefits;
