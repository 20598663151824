import React from "react";
import { FaRecycle, FaSolarPanel } from "react-icons/fa";
import { GiReceiveMoney } from "react-icons/gi";

import { Container, WrapperCard, Card } from "./styles";

const SectionSustentavel = () => {
  return (
    <Container>
      <h1>Sustentabilidade e Economia</h1>
      <hr />

      <WrapperCard>
        <Card>
          <span>
            <FaRecycle size={45} />
          </span>
          <div>
            <strong>Redução dos impactos ambientais</strong>
            <p>
              Não produz resíduos poluentes e gases de efeito estufa,
              contribuindo para um ar mais puro.{" "}
            </p>
          </div>
        </Card>
        <Card>
          <span>
            <FaSolarPanel size={45} />
          </span>
          <div>
            <strong>Provêm de um recurso natural e inesgotável</strong>
            <p>
              A irradiação solar é convertida em energia elétrica por meio de
              módulos fotovoltaicos.{" "}
            </p>
          </div>
        </Card>
        <Card>
          <span>
            <GiReceiveMoney size={45} />
          </span>
          <div>
            <strong>Economia de até 95% no seu bolso</strong>
            <p>
              Possibilita autonomia energética e uma economia de até 95% na sua
              conta de energia.
            </p>
          </div>
        </Card>
      </WrapperCard>
    </Container>
  );
};

export default SectionSustentavel;
