import styled from "styled-components";
import { Link as LinkGatsby } from "gatsby";

export const Container = styled.nav`
  width: 100%;
  height: 116px;
  padding: 32px 0 16px 0;
  transition: all 0.2s ease;

  @media (max-width: 1200px) {
    padding: 32px 32px 0 32px;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 860px) {
    flex-direction: column;
    padding-top: 0;

    display: ${(props) => (props.show ? "block" : "none")};

    > div {
      display: none;
    }
  }
`;

export const List = styled.ul`
  display: flex;
  align-items: center;
  padding: 0;

  a {
    margin-left: 20px;
  }

  @media (max-width: 860px) {
    width: 100%;
    display: ${(props) => (props.show ? "flex" : "none")};
    background: rgba(0, 0, 0, 0.85);
    flex-direction: column;
    padding-top: 56px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 50;

    a {
      margin: 24px 0 0 0;
    }
  }
`;

export const Item = styled.li`
  list-style: none;
  display: inline-block;
  padding: 16px;

  @media (max-width: 860px) {
    display: block;
    text-align: center;
  }

  .active {
    border-bottom: solid 3px #e66000;
  }
`;

export const Link = styled(LinkGatsby)`
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  text-decoration: none;

  :hover {
    border-bottom: solid 3px #eaaf1f;
  }
`;

export const Toggle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
  transition: all 0.2s ease;

  display: none;

  svg {
    position: absolute;
    top: 56px;
    right: 40px;
    z-index: 500;
  }

  @media (max-width: 860px) {
    display: flex;
    padding-top: 24px;
    transition: all 0.2s ease;
  }
`;

export const WrapperLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 250px;
  }

  @media (max-width: 860px) {
    img {
      width: 190px;
    }
  }

  @media (max-width: 440px) {
    width: 150px;
  }

  @media (max-width: 370px) {
    width: 100px;
  }
`;
