import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
  margin-bottom: 100px;
  padding: 64px 32px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  border-radius: 32px;
  box-shadow: 0 10px 64px rgba(27, 31, 35, 0.07);

  @media (max-width: 1200px) {
    max-width: 700px;
  }

  @media (max-width: 750px) {
    max-width: 440px;
  }

  @media (max-width: 470px) {
    max-width: 310px;
  }

  h1 {
    font-size: 46px;
    font-weight: bold;
    text-align: center;
    line-height: 56px;

    @media (max-width: 885px) {
      font-size: 36px;
    }
  }

  hr {
    margin: 0 auto;
    margin-top: 32px;
    margin-bottom: 64px;
    width: 140px;
    height: 3px;
    background: #e66000;
    border: 0;
  }
`;
