import React from "react";
import { GiHouse, GiFactory } from "react-icons/gi";
import { MdBusiness } from "react-icons/md";
import rural from "../../images/rural.svg";

import Bg from "./Background";

import { Container, WrapperCard, Card } from "./styles";

const SectionSolutions = () => {
  return (
    <Bg>
      <Container>
        <h1>Fornecemos soluções completas</h1>
        <hr />

        <WrapperCard>
          <Card>
            <GiHouse />
            <strong>Residências</strong>
          </Card>
          <Card>
            <MdBusiness />
            <strong>Comércios</strong>
          </Card>
          <Card>
            <GiFactory />
            <strong>Empresas</strong>
          </Card>
          <Card>
            <img src={rural} alt="área rural" />
            <strong>Áreas Rurais</strong>
          </Card>
        </WrapperCard>
      </Container>
    </Bg>
  );
};

export default SectionSolutions;
