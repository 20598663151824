import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: 1120px;
  margin: 100px auto;
  padding: 0 32px;

  h3 {
    color: #e66000;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 24px;
  }

  h1 {
    font-size: 46px;
    font-weight: bold;
    text-align: center;

    @media (max-width: 885px) {
      font-size: 36px;
    }

    @media (max-width: 400px) {
      font-size: 28px;
    }
  }

  hr {
    margin: 0 auto;
    margin-top: 24px;
    width: 140px;
    height: 3px;
    background: #e66000;
    border: 0;
  }

  p {
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    margin-top: 64px;
    text-align: center;
    color: #8a8a8a;
    font-size: 18px;
    line-height: 28px;
    background: #fff;
  }

  strong {
    font-family: "Widges", sans-serif;
    font-size: 14px;
  }
`;
