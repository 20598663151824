import styled from "styled-components";
import { Link } from "gatsby";
import { shade } from "polished";

export const Container = styled(Link)`
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  margin-left: 16px;

  padding: 12px 20px;
  font-weight: bold;
  background: linear-gradient(
    90deg,
    #e66000 8.1%,
    #eaaf1f 99.97%,
    #ffe671 99.98%
  );
  border-radius: 24px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);

  :hover {
    background: ${shade(0.06, "#e66000")};
  }

  @media (max-width: 440px) {
    padding: 8px 16px;
  }
`;
