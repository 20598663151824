import React, { useState } from "react";
import { Link as LinkGatsby } from "gatsby";
import { FaBars } from "react-icons/fa";
import { FiX } from "react-icons/fi";
import logoBranco from "../../../images/logobranco.png";

import Cta from "./Cta";

import {
  Container,
  Content,
  List,
  Item,
  Link,
  Toggle,
  WrapperLogo,
} from "./styles";

const NavMenu = () => {
  const [show, setShow] = useState(false);

  return (
    <Container>
      <Toggle>
        <WrapperLogo>
          <LinkGatsby to="/">
            <img src={logoBranco} alt="LOGO SF SOLAR" />
          </LinkGatsby>
        </WrapperLogo>

        {!show ? (
          <FaBars onClick={() => setShow(!show)} size={32} color={"#fff"} />
        ) : (
          <FiX onClick={() => setShow(!show)} size={32} color={"#fff"} />
        )}
      </Toggle>

      <Content show={show}>
        <WrapperLogo>
          <LinkGatsby to="/">
            <img src={logoBranco} alt="LOGO SF SOLAR" />
          </LinkGatsby>
        </WrapperLogo>

        <List show={show}>
          <Item>
            <Link to="/" activeClassName="active">
              HOME
            </Link>
          </Item>
          <Item>
            <Link to="/quemsomos/" activeClassName="active">
              A SF SOLAR
            </Link>
          </Item>
          <Item>
            <Link to="/contato/" activeClassName="active">
              CONTATO
            </Link>
          </Item>
          <Cta />
        </List>
      </Content>
    </Container>
  );
};

export default NavMenu;
