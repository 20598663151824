import React from "react";

import NavMenu from "../Home/NavMenu";
import Cta from "../../components/Cta";

import Bg from "../Home/Background";

import { Container, Box, H1, H2 } from "./styles";

const Home = () => {
  return (
    <Bg>
      <NavMenu />
      <Container>
        <Box>
          <H1>Reduza sua conta de</H1>
          <H2>Energia Elétrica</H2>
          <H2 style={{ marginBottom: 32 }}>em até 95%</H2>
          <Cta />
        </Box>
      </Container>
    </Bg>
  );
};

export default Home;
