import React from "react";

import Home from "../components/Home";
import SectionEmpresa from "../components/SectionEmpresa";
import SectionSustentavel from "../components/SectionSustentavel";
import SectionSolutions from "../components/SectionSolutions";
import SectionBenefits from "../components/SectionBenefits";
import SectionOrcamento from "../components/SectionOrcamento";
import Footer from "../components/Footer";

import SEO from "../components/seo";
import Layout from "../components/Layout";

const Index = () => {
  return (
    <Layout>
      <SEO title="SF Solar - A Empresa de Energia Solar" />
      <Home />
      <SectionEmpresa />
      <SectionSolutions />
      <SectionBenefits />
      <SectionSustentavel />
      <SectionOrcamento />
      <Footer />
    </Layout>
  );
};

export default Index;
