import React from "react";

import { Container } from "./styles";

const Cta = ({ children }) => {
  return (
    <Container to="/orcamento/">
      {children || "Solicite um Orçamento"}
    </Container>
  );
};

export default Cta;
